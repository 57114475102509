@import "../../styles/variables";

.pageHeader {
  background-color: $offWhite;
  overflow: hidden;
  position: relative;
  padding: 112px 0px;
  & > svg {
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    margin: auto;
    left: 50%;
    transform: translateX(-50%);
    z-Index: 1;
    opacity: 0.4;
    max-height: initial;
  }
}
.pageTitle {
  font-size: 3rem;
  line-height: 60px;
  font-weight: 600;
  padding-bottom: 16px;
  position: relative;
  &:after {
    display: block;
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 83px;
    height: 2px;
    background-color: $red;
  }
}
.staticMenu {
  padding: 16px;
  text-align: center;
  background-color: $red;
  & ul {
    padding: 32px 0 16px;
    border: 1px solid $white;
    & li {
      margin-bottom: .5rem;
      color: $white;
      font-weight: 600;
      & a {
        color: $white;
        text-decoration: underline;
      }
    }
  }
}
.address {
  margin: 25.6px 0px;
  text-align: center;
  & h3 {
    margin-bottom: 0.5rem;
    font-family: Domaine, serif;
    font-weight: 500;
    color: $blue;
    font-size: 18px;
    line-height: 22px;
  }
  & address {
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    line-height: 24px;
    color: $blue;
  }
}
.bodyContent {
  margin-bottom: 32px;
  @media (min-width: $tabletLand) {
    padding-left: 32px;
  }
  & h2 {
    margin: 0 0 32px 0;
    font-size: 30px;
    line-height: 42px;
    font-weight: 500;
  }
  & p {
    line-height: 30px;
  }
}
.sticky {
  @media (min-width: 1366px) {
    position: sticky;
    top: 130px;
    align-self: flex-start;
  }

}
